import React from 'react'
// import { graphql } from 'gatsby'

import MainContainer from '../container/main'
import SEO from '../components/seo'

import ContentHeroNew from '../components/content-hero-new'
import Contact from '../components/contact'
import Container from '../components/container'

const IndexPage = ({ data }) => (
  <MainContainer>
    <SEO title="Contact" />
    <Container>
      <ContentHeroNew headline="Contact Basaas" description="You got a question or feedback for us?" />

      <Contact type="contact" />
    </Container>
  </MainContainer>
)

export default IndexPage

// export const query = graphql`
//   query {
//     placeholderLandscape: file(relativePath: { eq: "placeholder-landscape.png" }) {
//       childImageSharp {
//         gatsbyImageData(layout: FULL_WIDTH, quality: 84)
//       }
//     }

//     placeholderPortrait: file(relativePath: { eq: "placeholder-portrait.png" }) {
//       childImageSharp {
//         gatsbyImageData(layout: FULL_WIDTH, quality: 84)
//       }
//     }

//     placeholderSquare: file(relativePath: { eq: "placeholder-square.png" }) {
//       childImageSharp {
//         gatsbyImageData(layout: FIXED, width: 200, quality: 84)
//       }
//     }
//   }
// `
